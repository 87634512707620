.container-form{
  margin-bottom: 10px;
  width: 100%;
}

.container-error-login {
  color: red;
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  margin-bottom: 10px;
}

.login-container {
  max-width: 390px;
}
