.modal {
  .title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .MuiDialog-paper {
    border-radius: 16px;
  }
}