.rule {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

.rule-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
}

.rules-wrapper {
  margin-top: 16px;
  margin-bottom: 16px;
}

.icon-container {
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
}

.rule-icon {
  margin-top: 0;
  margin-bottom: 0;
}

.dot {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #f0f0f0;
  margin-right: 8px;
}