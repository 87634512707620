.onboarding-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.contract-wrapper {
  height: 500px;
  overflow-y: auto;
}

.circle-icon {
  color: #D1D5DE;
  &.active {
    color: #531AFF;
  }
}

.container-circle-icon {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
}

.text-darkgrey {
  color: #6D7487;
}

.text-deepblue {
  color: #2828A4;
}

.install-dates {
  .MuiRadioGroup-root {
    .radio-item {
      text-transform: capitalize;
    }
  }
}