// .titleLarge {
//   font-size: 32px;
// }
body {
  background-color: #FDFDFF;
  color: #13161F;
}

.headlineLarge {
  font-size: 32px;
}

.headlineSmall {
  font-size: 24px;
}

.bodyLarge {
  font-size: 20px;
}

.bodyMedium {
  font-size: 16px;
}

.bodySmall {
  font-size: 14px;
}

.field-error {
  color: red;
  font-size: 0.8rem;
  margin-top: 0.2rem;
  margin-bottom: 5px;
}

.main {
  padding: 0 20px;
}

.text-center {
  text-align: center;
}

.password-title {
  font-size: 1.7rem;
  font-weight: normal;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.align-center {
  display: flex;
  align-items: center;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.capitalize {
  text-transform: capitalize;
}

h1 {
  font-size: 2rem;
  padding-top: 24pt;
  &.onboarding-title {
    color: #531AFF;
  }
  &.title-success {
    color: #13161F;
  }
}

.welcome {
  .message {
    font-size: 14px;
    margin-top: 1rem;
    color: #B44E74;
  }
  .user-name {
    font-size: 1.5rem;
    font-weight: bold;
    color: #2828A4;
  }
}

.title {
  font-size: 1.5rem;
}
.subtitle {
  font-size: 1.3rem;
}

.subtitle,
.title {
  font-weight: bold;
  color: #2828A4;
  &.hanpurple {
    color: #531AFF;
  }
  &.error {
    color: #FF4A1D;
  }
}

.subtitle-small {
  font-size: 1.2rem;
  font-weight: bold;
  color: #2828A4;
}

a {
  color: #13161F;
}

a.see-more {
  font-size: 1rem;
  color: #2828A4;
  text-decoration: none;
  font-weight: 600;
}

.balance-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.balance-cards,
.top-products,
.shelf-cards,
.configuration-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  
  .card {
    background-color: #FFF;
    border-radius: 10px;
    padding: 20px;
    flex-grow: 1;
    &.product {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 80px;
    }
  }
  .title {
    font-size: 1.8rem;
    font-weight: 600;
  }
  .circle-status {
    display: inline-block;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    margin-right: 10px;
  }
}

.accordion-card {
  background-color: #FFF;
  border-radius: 16px;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    user-select: auto;
  }
}

.container {
  background-color: #F2F5FB;
  min-height: 100vh;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  .product-card,
  .service-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 16px;
    text-align: center;
    cursor: pointer;
    .title {
      font-size: 1.2rem;
      font-weight: 600;
      margin-top: 10px;
      color: #2828A4;
    }
  }

  .product-card {
    box-sizing: border-box;
    border: 2px solid #FFF;
    background-color: #FFF;
    img {
      max-height: 60px;
      max-width: 100%;
    }
    &:hover {
      transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out;
      border-color: #531AFF;
    }
  }

  .service-card {
    &:hover,
    &.active {
      transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
      background-color: #531AFF;
      .title {
        color: #FFF;
      }
      svg .border {
        fill: white;
      }
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
}

ul, ol {
  margin-left: 20px; /* key property */
  line-height: 1.5rem;
}

.shelf-empty-state {
  .main {
    display: flex;
    background-color: #531AFF;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    color: white;
    img {
      height: 150px;
    }
  }
  .note {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #F2F5FB;
    border-radius: 16px;
  }
}

.activity-detail,
.product-detail {
  .subtable {
    tr {
      td {
        padding: 0.3rem;
      }
    } 
  }
}

.activity-search {
  .MuiInputBase-root {
    border-radius: 30px;
    background-color: white;
  }
}

.service-details,
.summary-card {
  background-color: white;
  border-radius: 16px;
  padding: 20px;
  .title {
    font-size: 1.5rem;
    font-weight: 600;
  }
}

.confirmation-modal,
.transaction-modal {
  max-width: 470px;
  width: 400px;
  .title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #531AFF;
  }
  .paragraph {
    line-height: 1.8rem;
    b {
      color: #531AFF;
    }
  }
}

.header {
  .header-desktop {
    .MuiButtonBase-root {
      &.active {
        color: #2828A4;
        background-color: #E9A9C1;
      }
      display: flex;
    }
  }
}

.MuiTableRow-root.no-border .MuiTableCell-root {
  border-bottom: none !important;
}
.MuiTableRow-root.border .MuiTableCell-root {
  padding-bottom: 20px;
  border-bottom: 2px solid #13161F !important;
}

// .profile-form {
//   .MuiOutlinedInput-root {
//     border: none;
//   }
// }